.pm-timesheet-range {
    td > span.p-highlight {
        color: #ffffff !important;
        background: #009cc2 !important;
    }
    td.p-datepicker-today > span:not(.p-highlight) {
        background: gray !important;
        color: #ffffff !important;
        border: none !important;
    }
    td > span {
        font-weight: 600 !important;
    }
}
#pm-edit-following {
    height: 50%;
    .p-dialog-header {
        background: black;
    }
    .ql-editor {
        font-size: 18px;
    }
}
.pm-timesheet-date {
    td > span.p-highlight {
        color: #ffffff !important;
        background: black !important;
    }
    td.p-datepicker-today > span:not(.p-highlight) {
        background: gray !important;
        color: #ffffff !important;
        border: none !important;
    }
    td > span {
        font-weight: 600 !important;
    }
}

.pm-filterbar {
    .p-calendar {
        height: 37px !important;
        .p-button {
            background: #777;
            box-shadow:none !important;
            outline: none !important;
            border: none !important;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .p-button:hover {
            background: #999;
        }
        .p-inputtext {
            box-shadow: none !important;
            border: inherit;
            font-weight: 600;
            font-size: 18px;
            border-radius: 0px
        }
        .p-inputtext:focus {
            border-bottom: solid 2px black !important;
        }
    }
}
.log-dropdown-panel{
    z-index: 5000 !important;
}
.pm-timesheet-logs {
    background:transparent;
    font-size: 15px;
    /*
    tbody > tr:not(:last-child):not(:first-child) {
        box-shadow: none !important;
        border-bottom: solid 1px gray;
    }*/
    tbody > tr:not(.p-rowgroup-header):hover {
        background: #ddd !important;
    }
    tbody > tr {
        background: transparent !important;
    }
    .p-datatable-emptymessage {
        font-size: 16px !important;
    }
    th, .p-datatable .p-datatable-thead > tr > th {
        background: transparent !important;
    }
    .p-datatable-wrapper {
        border:none !important;
        thead {
            border-bottom-width: 2px !important;
            border-color: inherit !important;
        }
    }
    .p-rowgroup-header > td {
        justify-content: start;
        font-weight: 600;
        padding-bottom: 0px !important;
    }
}

.pm-tag-filters {
    .pm-tag {
        cursor: pointer;
        color: gray;
        margin-right: 20px;
        font-weight: 500;
    }
    .pm-tag:hover {
        color: black;
    }
}

.pm-timesheet > .p-datatable-wrapper {
    overflow: visible !important;
}
.pm-timesheet {
    tr > th {
        border-bottom-width: 0px !important;
    }
    .p-datatable-wrapper {
        z-index: 0;
    }
    td.log-expander > svg:hover {
        color: black !important;
    }
    th.log-expander {
        padding: 0px !important;
    }
    .p-datatable-emptymessage {
        font-style: italic;
    }
    th.log-item {
        justify-content: end;
    }
    td.log-item {
        vertical-align: top;
    }
    th.log-entry-notes > div {
        justify-content: start;
        align-items:start;
    }
    .p-datatable-row-expansion {
        box-shadow: inset 0px 5px 20px -10px !important;
        background: #f0f0f0 !important;
        td {
            background: transparent !important;
        }
    }
    .p-button {
        background: black;
        outline: none !important;
        box-shadow: none !important;
    }
    .p-button:hover {
        background: #444;
    }
    padding: 50px 100px;
    thead {
        border-bottom: solid 1px #aaa !important;
        
    }
    .p-column-title{
        font-size: 18px !important;
    }
    tr {
        td.log-approved {
            z-index: 0;
        }
        th.log-artists, th.log-artists > div {
            justify-content: center;
            background: transparent !important;
            background: transparent !important;
            font-weight: 600;
        }
        th.log-approved > div {
           text-align: left;
           justify-content: start;
       } 
       th.log-projects, th.log-departments {
           justify-content: center;
       }
       th.log-date {
           justify-content: end;
           display: flex;
           text-align: right;
       }
       th.log-hours, th.log-tasks, th.log-submitted, th.log-tomorrow {
           justify-content: center;
       }
    }
    

    .p-datatable-wrapper {
        border: solid 1px;
        border-radius: 5px;
    }
    .log-project-tag{
        font-size: 18px;
        padding-left: 12px;
        text-align:center;
        color: gray;
    }
    .log-project-tag:not(:last-child)::after {
        content: ','
    }
    .log-project-tag:hover {
        cursor: pointer;
        color: black;
        padding-left: 0px;
        -webkit-text-stroke-width: 0.25px;
    }
    .log-project-tag:hover::before {
        content: '#'
    }

    tbody > tr {
        td.log-date {
            text-align: right;
            justify-content: end;
        }
        td.log-projects > div {
            text-align: center;
            font-weight: 600;
            justify-content: center;
        }
        td.log-tasks {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
        }
        td.log-hours {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
        }
        td.log-submitted {
            text-align: center;
        }
    }

    
    .p-datatable .p-datatable-tbody > tr {
        font-size: 18px;
        font-weight: 400;
    }

    .log-tick {
        font-size: 35px;
        color: rgb(0, 133, 119);
    }
}

#pm-new-timelog {
    height: fit-content;
    #Project.p-dropdown, #Board.p-dropdown, #Group.p-dropdown {
        width: 350px;
    }
    #Item.p-dropdown, #Review.p-dropdown{
        width: 620px;
    }
    .p-dialog-header {
        padding: 0px;
    }

    .p-dialog-title {
        padding: 15px 20px !important;
        background: black;
    }
    .p-button-icon-only, .p-button-icon-only:hover {
        background: transparent !important;
    }
    .pm-dialogHeader > .p-button-icon-only, .pm-dialogHeader > .p-button-icon-only:hover {
       height: 100% !important;
       background: transparent !important;
    }
    .pm-dialogHeader > .p-button-icon-only {
        color: #ccc !important;
    }
    .pm-dialogHeader > .p-button-icon-only:hover {
        color: white !important;
    }
    .pm-dialogHeader > span > span > span {
        margin-right: 50px !important;
    }
    .p-component.p-inputtext {
        border:none;
        border-bottom: solid 1px black !important;
        box-shadow:none;
        border-radius: 0;
        width: 100%;
        font-size: 20px;
    }
    .p-component.p-inputtext:focus {
        border-color: black !important;
    }

    .p-float-label > label{
        margin-top: -0.7rem;
        margin-left: -0.8rem;
        top: 0;
        font-size: 14px;
    }
    .p-float-label > label{
        margin-top: -0.7rem;
        margin-left: -0.8rem;
        top: 0;
        font-size: 14px;
    }
    .p-button {
        background: black !important;
    }

    .p-button:hover {
        background: #444 !important;
    }

    .p-calendar > input, .p-calendar > button, .p-inputnumber > input, 
    .p-inputnumber > .p-inputnumber-button-group {
        height: 45px;
    }

    .p-dropdown {
        box-shadow: none !important;
        border:none;
        border-bottom: solid 2px #ccc;
        border-radius: 0;
        width: 500px;
        height: 45px;
    }

    .p-dropdown.p-focus {
        border-color: black;
    }
    .p-dropdown-label.p-inputtext {
        border: none !important;
    }

    .p-dialog-footer {
        padding: 1.5rem !important;
    }

    .p-fieldset .p-fieldset-legend {
        padding: 0.5rem 1rem;
        width: 200px !important;
        text-align:center;
        margin: 0;
        border: 1px solid #000000;
        color: #ffffff;
        background: #262626;
        font-weight: 700;
        border-radius: 6px;
        border-bottom: none;
        width: fit-content;
        border-radius: 5px;
        text-align: left;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .p-fieldset-legend-text {
        font-weight: 600;
        font-size: 20px;
    }
    .p-toggleable-content {
        border: solid 1px black;
        border-radius: 5px;
        padding: 1rem 1rem 0.5rem 1rem;
        border-top-left-radius: 0px;
    }
}


.p-contextmenu.pm-sheet-context {
    background: transparent !important;
}

.pm-sheet-context {
    .p-menu-separator {
        margin: 0 !important;
        height: 1px !important;
        background: gray !important;
        border: none !important;
    }
    li.p-menuitem.pm-status-option {
        //background: unset;
        box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.4);
        color: white;
        width: 300px;
        font-weight:400;
        border: solid 1px black;
    }
    .p-menuitem.p-menuitem-active.pm-status-option {
        color: black !important;
        border: solid 2px black;
        background:#ddd !important;
    }

    .p-menuitem {
        background: #333;
        color: white;
    }
    .p-menuitem-active {
        background: #444;
        color: white;
    }
    .pi-angle-right:before {
        content: none;
    }
    .p-menuitem > .p-menuitem-link,  
    .p-menuitem > .p-menuitem-link > .p-menuitem-text{
        color: inherit !important;
        font-weight: 300;
    } 
    .p-menuitem.p-menuitem-active > .p-menuitem-link {
        background: unset !important;
        color: unset !important;
        -webkit-text-stroke: 0.3px;
    }
    .p-menu-item-active > .p-menuitem-link > .p-menuitem-text {
        -webkit-text-stroke: 0.3px;
    }

    .p-menuitem.pm-status-option > .p-menuitem-link > .p-menuitem-text, li.p-menuitem.pm-status-option > .p-menuitem-link {
        color: inherit !important;
    }
}