.pm-chart-dropdown {
    .p-dropdown-item {
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important;
    }
}
#pm-charts {
    
    .p-dropdown {
        //border: none;
        //border-bottom: solid 2px #ccc   ;
        //border-radius: 0;
        box-shadow: none !important;
        outline: none !important;
        position:absolute;
        top: 20px;
        right: 20px;
    }
    .p-inputtext {
        padding: 0.5rem 0.75rem;
    }
    .p-inputtext:focus-visible, .p-dropdown:focus-visible, .p-inputtext:enabled:hover
    .p-dropdown:not(.p-disabled).p-focus, .p-inputtext:not(.p-disabled).p-focus {
        border-bottom: solid 2px black !important;
        border-color: black !important;
    }
    .p-dropdown:not(.p-disabled):hover, p-inputtext:not(.p-disabled):hover,
    .p-inputtext:enabled:hover, .p-inputwrapper-focus {
        border-color: black !important;
    }
    .p-dropdown-items > .p-dropdown-item.p-highlight,
    .p-divider.p-divider-horizontal:before {
        border-top: solid 2px lightgray;
    }
}