
.pm-noscroll-dropdown {
  max-height:unset !important;
}
#pm-upload-review_content {
    overflow-y: hidden !important;
}

.pm-dialog {
    
    .p-dialog-content {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        border-radius: 5px;
        border: solid 1px black;
        background: transparent;
    }
    .p-dropdown-label.p-inputtext {
        border: none !important;
    }
    .p-button.p-button-rounded {
        border:none;
        box-shadow:none;
        background:transparent;
        position:absolute;
        right: 0px;
        top:0px;
        color: white !important;
    }
    .p-button.p-button-rounded:hover {
        background: transparent !important;
        //color: white !important;
        -webkit-text-stroke-width: 1px;

    }
    .p-button {
      background: #333;
      border-color: black;
      font-weight: 400;
      font-family: 'Open Sans';
    }
    .p-button:hover {
      background: #555 !important;
      border-color:black !important;
      outline: none !important;
      font-weight: 600 !important;
    }
    .p-fileupload-row:first {
      width: fit-content;
      padding-top: 0px !important;
    }
    .p-fileupload-row > div{
      padding: 0px;
    }
    .p-fileupload .p-fileupload-content {
      padding:20px 10px;
      padding-top: 5px;
      margin: 0px 40px;
      border: none;
      border-top: solid 2px gray;
      margin-top: -20px;
    }

  .p-fileupload-files {
    margin-top: 10px;
  }
    .p-fileupload-row {
      padding: 20px 3px !important;
      border-bottom: solid 1px gray;
      .p-button.p-button-icon-only {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .p-fileupload-filename {
      width: 100%;
      text-align:left;
    }
    .p-button:focus {
      box-shadow: none !important;
    }
    .p-fileupload .p-progressbar {
      height: 0.4rem !important;
      display:none;
    }

    .p-dialog-header {
        background: #333;
        width: 75vw;
        padding: 15px 20px;
        color: white;
        box-shadow: inset 0px 0px 55px -30px black;
  
        .p-dialog-header-icons {
          background: transparent !important;
          border: none !important;
          box-shadow: none !important;
          button {
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
          }
        }
    }
    .p-dialog-header-close-icon:hover {
      color: white !important;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
      color: #ffffff !important;
      border-color: transparent;
      background: #444;
      box-shadow:none !important;
    }
  }

#pm-upload-review {
    .p-inputtext, #pm-upload-review .p-dropdown {
        border: none;
        border-bottom: solid 2px #ccc;
        border-radius: 0;
        box-shadow: none !important;
        outline: none !important;
        vertical-align: middle;
        padding: 0px;
        align-self: center;
        /* height: 100%; */
        align-items: center;
        vertical-align: middle;
    }
    .pm-itemUpload-thumbnail {
        max-width: 100px;
        max-height: 100%;
        width: 100px;
        object-fit: cover;
        height: 100%;
        border-radius: 0px;
        border-right: solid 1px black;
        background:#eee;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .pm-itemUpload-thumbnail {
        background: #eee;
    }
    .pm-itemUpload-thumbnail > svg {
        transform: scale(0.6);
        border: none;
        color: black;
        background: transparent;
    }
    .pm-itemUpload-row {
        overflow: hidden;
        position:relative;
        height: 70px;
        align-content: center;
        border-top: solid 1px #aaa;
        border-left: solid 20px #ccc;
        border-right: solid 20px #ccc;
        margin-left: 6px;
    }
    .pm-itemUpload-row:hover {
        background: #eee;
    }
    .pm-itemUpload-row:last-child {
        border-bottom: solid 1px #CCC;
    }
    .pm-itemUpload-col.thumbnail {
        min-width:100px;
        width: 100px;
        max-width: 100px;
    }
    .pm-itemUpload-col {
        text-align:center;
        font-size: 13px;
        align-self: center;
    }
    .pm-itemUpload-col.date {
        min-width: 150px;
    }
    .pm-itemUpload-col.name {
        font-weight: 600;
        margin-left: 120px;
    }
    .pm-itemUpload-col.type {
        font-weight: 600;
    }
    .col {
        align-self:center;
        padding: 0px;
        width: 100%;
        align-content: center;
        align-items: center;
        .p-button {
            height: 30px !important;
            width: 30px !important;

        }
    }
    .col:last-child {
        margin-left: 20px;
    }
    .p-float-label > label{
        margin-top: -1rem;
        margin-left: -0.8rem;
    }
    .col {
        .p-inputtext, .p-dropdown {
            font-size:14px;
            padding: 6px 0px;
            height: 35px;
            box-shadow: none !important;
            outline: none !important;
            border: solid 1px black;
            border-radius: 5px;
            width: 100%;
        }
    }
    .p-scrollpanel {
        height: 100% !important;
    }
    .p-scrollpanel-content {
        height: 100% !important;
        overflow-x: hidden;
    }
    .p-dialog-content {
        height: calc(100vh - 200px) !important;
    }
    .p-dialog-header {
        padding: 0px !important;
    }
    .p-dropdown-items > .p-dropdown.item.p-highlight, li.p-highlight {
        background: transparent !important;
    }
    .p-steps .p-steps-item.p-highlight .p-steps-title {
        font-weight: 400;
        color: white;
    }
    .p-menuitem-link {
        background: transparent !important;
        box-shadow: none !important;
        cursor: default;
    }
    .p-steps-number, .p-steps-title {
        cursor: pointer;
    }
    .p-dialog-footer {
        background: #333;
        color: white; 
        padding: 0.5rem 1rem;
    }
    .p-progressbar-label {
      line-height: 40px;
    }
    .pm-dialogHeader {
        width: 100%;
        background: gray;
        color: white;
        font-size: 22px;
        font-weight: 600;
        padding: 10px 20px;
    }
    .p-dropdown-label .p-inputtext {
        margin-top:15px;
    }
    .p-inputtext, .p-dropdown {
        border: none;
        border-bottom: solid 2px #ccc   ;
        border-radius: 0;
        box-shadow: none !important;
        outline: none !important;
        height: 35px;

    }
    .p-fileupload-advanced {
        margin-top: -60px;
    }
    .p-inputtext:focus-visible, .p-dropdown:focus-visible, .p-inputtext:enabled:hover
    .p-dropdown:not(.p-disabled).p-focus, .p-inputtext:not(.p-disabled).p-focus {
        border-bottom: solid 2px black !important;
        border-color: black !important;
    }
    .p-dropdown:not(.p-disabled):hover, p-inputtext:not(.p-disabled):hover,
    .p-inputtext:enabled:hover, .p-inputwrapper-focus {
        border-color: black !important;
    }
    .p-dropdown-items > .p-dropdown-item.p-highlight,
    .p-divider.p-divider-horizontal:before {
        border-top: solid 2px lightgray;
    }

    .p-fileupload .p-fileupload-buttonbar {
        border: none;
        //padding: 0;
        margin-bottom: 30px;
        display: flex;
        justify-content: end;
    }
    label {
        top: -0.3rem;
        font-size: 14px;
        font-weight: 600;
        color: black !important;
    }
}

.p-dropdown-items > .p-dropdown.item.p-highlight,
li.p-highlight {
    color: black !important;
    font-weight: 600 !important;
    background: #eee !important;
}