.pm-galleryUpdate {
    button {
        background: black;
    }
    .p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
        background: #444;
    }
    .p-inputtext {
        border:none;
        outline: none;
        box-shadow: none !important;
        border-bottom: solid 1px black;
        border-color: black !important;
        border-radius: 0px !important;
    }
    .p-inputtext:enabled:focus {
        border-color:black;
        border-width: 1.5px;
    }
}