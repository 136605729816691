
#pm-edit-task {
    .p-scrollpanel-bar-x {
        display:none !important;
    }
    .p-scrollpanel-content {
        overflow-x: hidden;
    }
    .pm-attr-note {
        font-style: italic;
        font-size: 12px;
        margin-top: -15px;
        margin-left: 20px;
        color: #000;
        font-weight: 400;
    }

    .p-inputtext {
        border:none;
        outline:none;
        border-bottom: solid 2px #ccc;
        box-shadow:none !important;
        border-radius:0;
        width: 100%;
    }
    .p-inputtext:focus-visible, .p-dropdown:focus-visible, .p-inputtext:enabled:hover
    .p-dropdown:not(.p-disabled).p-focus, .p-inputtext:not(.p-disabled).p-focus {
        border-bottom: solid 2px black !important;
        border-color: black !important;
    }
    .p-dropdown:not(.p-disabled):hover, p-inputtext:not(.p-disabled):hover,
    .p-inputtext:enabled:hover, .p-inputwrapper-focus {
        border-color: black !important;
    }
    .p-dropdown-items > .p-dropdown-item.p-highlight,
    .p-divider.p-divider-horizontal:before {
        border-top: solid 2px lightgray;
    }

    .pm-dialogHeader {
        width: 100%;
        background: gray;
        color: white;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 20px;
    }
    .p-dialog-header {
        background: #333;
        width: 75vw;
        padding: 15px 20px;
        color: white;
        box-shadow: inset 0px 0px 55px -30px black;
  
        .p-dialog-header-icons {
          background: transparent !important;
          border: none !important;
          box-shadow: none !important;
          button {
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
          }
        }
    }
    .p-dialog-header-close-icon:hover {
      color: white !important;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
      color: #ffffff !important;
      border-color: transparent;
      background: #444;
      box-shadow:none !important;
    }
  }