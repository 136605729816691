.p-autocomplete-panel {
    .p-autocomplete-items {
        padding: 0 !important;
    }
    .p-autocomplete-items > li.p-autocomplete-item {
        padding: 10 !important;
    }
    .p-button {
        position:relative ;
    }
    .p-button > img {
        position:absolute;
        left:0px;
        top:0px;
    }
    .p-button > div {
        position:absolute;
        left:0px;
        width: 40px;
    }
    .p-button, .p-button > img {
        width: 40px !important;
        height: 40px !important;
        
    }
}
#pm-new-ticket {
    .p-dialog-header {padding: 0}
    .pm-dialogHeader {
        padding: 10px;

        button {
            height: unset !important;
            span {
                color: white;
            }
        }
    }
    height: 80%;
    .p-autocomplete-token {
        background: white !important;
        padding: 0px;
    }
    .p-autocomplete-token > span > div {
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 400;
    }
    .p-inputtext.p-chips-multiple-container {
        box-shadow:none;
        border:none;
        border-bottom: solid 2px #ccc;
        border-radius:0;
        width: 100%;
    }

    
    #Requestors {
        width: 100%;
    }
    .p-inputtext.p-chips-multiple-container.p-focus {
        border-color: black;
    }
    .pi.pi-times {
        margin-left: 10px;
        cursor: pointer;
    }
    .p-autocomplete-token-icon {
        display: none;
    }
    .p-dialog-header {
        background: black !important;
    }
    .p-button-icon-only {
        height: 30px;
    }
    
    .p-dropdown {
        box-shadow: none !important;
        border:none;
        border-bottom: solid 2px #ccc;
        border-radius: 0;
        width: 200px;
    }

    .p-dropdown.p-focus {
        border-color: black;
    }

    .p-component.p-inputtext {
        border:none;
        border-bottom: solid 2px #ccc !important;
        box-shadow:none;
        border-radius: 0;
        width: 100%;
    }
    .ql-container.ql-snow {
        border: 1px solid #aaa;
    }
    .ql-toolbar.ql-snow {
        border: 1px solid #aaa;
    }
    .p-float-label > label{
        margin-top: -0.7rem;
        margin-left: -0.8rem;
        top: 0;
        font-size: 14px;
    }
    .p-dropdown-label.p-inputtext {
        border: none !important;
    }
    .p-component.p-inputtext:focus {
        border-color: black !important;
    }
}
.pm-tag-filter {
    font-weight: 600;
    cursor: pointer;
    font-size: 20px;
    color: #888 !important;
}

.pm-supportItem {
    border: solid 1px black;
    border-radius: 5px;
    width: 100%;
}
.pm-supportItem:hover {
    box-shadow: inset 0px 0px 1px 1px black;
}
.p-datatable .p-datatable-thead > tr > th > div > span {
    font-size: 14px;
}
.p-row-toggler {
    box-shadow: none !important;
    background: transparent !important;
    opacity: 0.45;
}
.p-row-toggler:hover {
    opacity: 1;
}
.p-column-header-content, td {
    justify-content: center;
}
.p-datatable-row-expansion > td {
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.p-datatable .p-datatable-tbody > tr {
    box-shadow: inset 0px -2px 5px -5px;
    font-size: 14px;
}
.p-datatable .p-datatable-tbody > tr:hover {
    background:#eee
    
}

.p-datatable .p-datatable-thead > tr > th{
    box-shadow: none !important;
}
.p-datatable >tr > th:first-child {
    border-top-left-radius: 5px;
}
.p-datatable >tr > th:last-child {
    border-top-right-radius: 5px;
}
td.ticket-requestor {
    justify-content: end !important;
    display:flex;
}
td.ticket-title {
    width: 400px;
}
th.ticket-title > div {
    justify-content: start !important;
}
th.ticket-requestor > div {
    justify-content: end !important;
}
th.ticket-assignee > div {
    justify-content: start !important;
}
td.ticket-status {
    display: flex;
}
td.ticket-center {
    text-align: center !important;
}
td.ticket-asignee {
    display:flex;
    justify-content: start;
}
.pm-ticket-container, td {

    .pm-ticket-date {
        min-width: 250px;
        text-align: right;
        margin-right: 20px;
    }
    .pm-ticket-comments {
        min-width: 150px;
        text-align:center;
    }
    .pm-ticket-name {
        width: stretch;
        font-weight: 600;
        height: 100%;
        padding-left: 20px;
        height: 53px;
        line-height: 53px;
    }
    .pm-ticket-status {
        width: 300px;
        min-width: 300px;
        color: white !important;
        text-align: center;
        height: 100%;
        font-weight: 600;
        height: 53px;
        line-height: 53px;
        border-left: solid 1px black;
    }
    .pm-ticket-priority {
        width: 150px;
        min-width: 150px;
        color: white !important;
        text-align: center;
        height: 100%;
        font-weight: 600;
        height: 53px;
        line-height: 53px;
        border-right: solid 1px black;
    }
    //border-bottom: solid 1px gray;
    .pm-tag-filter {
        font-weight: 600;
        cursor: pointer;
        font-size: 20px;
        color: #888 !important;
    }

    .p-avatar.p-avatar-lg {
        width: 53px !important;
        height: 53px !important;
        font-size: 15px;
        color: white;
        border:inset 1px black!important;
    }

    
    .pm-badge {
        max-width:53px;
        min-width:53px;
        width:53px;
        height:53px;
        min-height:53px;
        max-height:53px;
        color: white;
        border-radius: 50%;
        display:flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        cursor: pointer;
        z-index:5;
        border:inset 1px black !important;
        box-shadow:none !important;
    }
    .pm-badge.suspended {
        border: none !important;
        opacity: 0.5;
    }
    .pm-badge:not(.suspended):hover {
        background: #aaa !important;
    }
    .pm-badge:hover > img {
        opacity: 0.6;
    }
    .pm-badge > svg {
        text-align: center;
        margin:auto !important;
        font-size: 1.25rem !important;
    }
}


.pm-support-context {
    background: transparent;
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
        
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #555;
        border-radius:5px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .p-submenu-list {
        background: transparent;
        height: 280px;
        overflow-y:scroll;
    }
    .p-menu-separator {
        margin: 0 !important;
        height: 1px !important;
        background: gray !important;
        border: none !important;
    }
    .pm-status-menu {
        width: 270px !important;
        height: 40px;
        background:white;
        .p-menuitem-link {
            //justify-content: center;
            .p-menuitem-text {
                font-weight: 400 !important;
                color: white !important;
                font-size: 14px;
            }
        }
        border: solid 2px black;
        border-top-width: 0px;
        svg {
            font-size: 1.25em;
            margin-right: 1rem;
            margin-top: -0.2rem; 
        }
    }
    .pm-status-menu:first-child {
        border-top-width: 2px;
    }
    .pm-status-menu:hover {
        filter:brightness(1.25);
    }
    li.p-menuitem.pm-status-option {
        //background: unset;
        box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.4);
        color: white;
        width: 300px;
        font-weight:400;
        border: solid 1px black;
    }
    .p-menuitem.p-menuitem-active.pm-status-option {
        color: black !important;
        border: solid 2px black;
        background:#ddd !important;
    }

    .p-menuitem {
        background: #333;
        color: white;
    }
    .p-menuitem-active {
        background: #444;
        color: white;
    }
    .pi-angle-right:before {
        content: none;
    }
    .p-menuitem > .p-menuitem-link,  
    .p-menuitem:not(.pm-status-menu)> .p-menuitem-link > .p-menuitem-text{
        color: inherit !important;
        //font-weight: 300;
        font-size:14px;
    } 
    .p-menuitem.p-menuitem-active > .p-menuitem-link {
        background: unset !important;
        color: unset !important;
        //-webkit-text-stroke: 0.5px;
    }
    .p-menu-item-active > .p-menuitem-link > .p-menuitem-text {
        //-webkit-text-stroke: 0.5px;
    }

    .p-menuitem.pm-status-option > .p-menuitem-link > .p-menuitem-text, li.p-menuitem.pm-status-option > .p-menuitem-link {
        color: inherit !important;
    }

    .pm-statusOption {
        border: solid 1px black;
        font-weight: 600 !important;
        box-shadow: inset 0px 2px 10px -2px rgba(0, 0, 0, 0.2);
        width: 400
    }

    .pm-statusOption:hover {
        //font-weight: 800 !important;
        filter:brightness(1.15);
    }
}

.speech-bubble {
	position: relative;
	border-radius: .4em;
    color: white !important;
    width: fit-content;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 24px solid transparent;
	border-top-color: inherit;
	border-bottom: 0;
	border-left: 0;
	margin-left: -12px;
	margin-bottom: -24px;
}
