header {
    //letter-spacing: 0.1rem;
    .p-avatar.p-avatar-lg {
        width: 35px !important;
        max-width: 35px !important;
        min-width: 35px !important;
        min-height: 35px !important;
        height: 35px !important;
        max-height: 35px !important;
        font-size: 15px;
        color: black !important;
        background: white !important;
        border: solid 1px black;
        

    }
    .pm-avatar-image {
        width: 35px !important;
        max-width: 35px !important;
        min-width: 35px !important;
        min-height: 35px !important;
        height: 35px !important;
        max-height: 35px !important;
        border-radius:50%;
        border: solid 1px black;
    }
    .pm-header {
        position:absolute;
        top:0px;
    }

    .pm-titlebar {
        
        width:100vw !important;
        height:45px !important;
        //background: gray;
        box-shadow: inset 0px 15px 15px -10px rgba(0, 0, 0, 0.5);
        padding-left: 30px;
        font-size: 15px;
        justify-content: start;
        vertical-align: middle;
        height: 100%;
        display: flex;
        
    }
    .pm-title {
        word-spacing: 5px !important;
        font-weight: 400;
        letter-spacing: 0.05rem;
        font-size: 17px;
        //letter-spacing: 0.13rem;
        //font-weight: 400;
    }
    .dropdown-divider {
        //margin-top: 0 !important;
        background:white !important;
        height: 2px !important;
    } 
    
    .bg-dark, .dropdown-menu-dark {
        background-color: #333 !important;
    }
    .dropdown-menu {
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
    }
    .btn-primary {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        //letter-spacing: 0.1rem;
        padding:0px !important;
    }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
        background-color: #444 !important;
    }
    .dropdown-item, .dropdown-menu  {
        background-color: #333 !important;
    }
    .dropdown-menu {
        min-width: 7.5rem;
    }
    .dropend > .btn {
        padding: 0.25rem 0 !important;
        text-align: left !important;
        font-size: 16px !important;
        //letter-spacing: 0.1rem;
        font-weight:300;
        opacity:0.8;
    }
    a.dropdown-item, div.dropdown-item {
        font-size: 16px !important;
        //letter-spacing: 0.1rem !important;
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
        font-weight:300;
        opacity:0.8;
        //margin: 2px 0px;
    }
    .dropend:hover, .dropdown-item:hover {
        background-color: #444 !important;
        -webkit-text-stroke-width: 0.35px;
        opacity: 1;
    }

    .dropdown-toggle:after { 
        content: none !important;
        margin-left: 0.55em;
    }

    .p-menuitem-active > .p-menuitem-link {
        font-weight: 600 !important;
      }
    .dropend > button:hover, .dropdown-item:hover{
        //font-weight:600 !important;
        -webkit-text-stroke-width: 0.5px;
        opacity: 1;
    }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #555;
    border-radius:5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}