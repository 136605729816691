body {
  margin: 0;
  font-family: 'Open Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
}
.p-component {
  font-family: 'Open Sans' !important;
  font-weight:300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root, button {
  font-family: 'Open Sans' !important;
}

.p-dropdown-panel {
  ::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #555;
    border-radius:5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
}